import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  DsIntroduce,
  DsDepartment,
  DsStatus,
  Root,
  DsHello,
  DsHistory,
  DsWay,
  DepartmentAddmission,
  DepartmentProcess,
  DepartmentRoadmap,
  Professor,
  Administrations,
  Lab,
  GraduateInfo,
  Notice,
  Employment,
  Reference,
  DataScience,
  Relation,
  GraduateProcess,
  Lecturer
} from '../pages';
import { api } from '../utils/api';
import { SWRConfig } from 'swr';

const App: React.FC = () => {
  const swrConfig = {
    fetcher(url: string, params: object) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/ds/introduce" component={DsIntroduce} />
          <Route exact path="/ds/department" component={DsDepartment} />
          <Route exact path="/ds/hello" component={DsHello} />
          <Route exact path="/ds/status" component={DsStatus} />
          <Route exact path="/ds/history" component={DsHistory} />
          <Route exact path="/ds/way" component={DsWay} />
          <Route
            exact
            path="/department/addmission"
            component={DepartmentAddmission}
          />
          <Route
            exact
            path="/department/process"
            component={DepartmentProcess}
          />
          <Route
            exact
            path="/department/roadmap"
            component={DepartmentRoadmap}
          />
          <Route exact path="/member/professor" component={Professor} />
          <Route
            exact
            path="/member/administrations"
            component={Administrations}
          />

          <Route exact path="/member/lab" component={Lab} />
          <Route exact path="/member/lecturer" component={Lecturer} />
          <Route exact path="/graduate/addmission" component={GraduateInfo} />

          <Route exact path="/community/notice" component={Notice} />
          <Route exact path="/community/employment" component={Employment} />
          <Route exact path="/community/reference" component={Reference} />
          <Route exact path="/community/dataScience" component={DataScience} />
          <Route exact path="/community/relation" component={Relation} />
          <Route exact path="/graduate/process" component={GraduateProcess} />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
