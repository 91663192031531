import loadable from '@loadable/component';

export const Root = loadable(() => import('./Root'));
export const DsIntroduce = loadable(() => import('./ds/Introduce'));
export const DsDepartment = loadable(() => import('./ds/Department'));
export const DsStatus = loadable(() => import('./ds/DepartmentStatus'));
export const DsHello = loadable(() => import('./ds/Hello'));
export const DsHistory = loadable(() => import('./ds/History'));
export const DsWay = loadable(() => import('./ds/DsWay'));
export const DepartmentAddmission = loadable(
  () => import('./department/Addmission'),
);
export const DepartmentProcess = loadable(() => import('./department/Process'));
export const DepartmentRoadmap = loadable(() => import('./department/Roadmap'));
export const Professor = loadable(() => import('./member/Professor'));
export const Lecturer = loadable(() => import('./member/Lecturer'));
export const Administrations = loadable(
  () => import('./member/Administrations'),
);

export const Lab = loadable(() => import('./member/Lab'));
export const GraduateInfo = loadable(() => import('./graduate/GraduateInfo'));

export const Notice = loadable(() => import('./community/Notice'));
export const Employment = loadable(() => import('./community/Employment'));
export const Reference = loadable(() => import('./community/Reference'));
export const DataScience = loadable(() => import('./community/DataScience'));
export const Relation = loadable(() => import('./community/Relation'));
export const GraduateProcess = loadable(
  () => import('./graduate/GraduateProcess'),
);
